import React from "react";
import { useTranslation } from 'react-i18next';
// Css
import "./LoginFooter.scss";

/**
 * Login Footer
 * @param {string} footerText | footerText for brand, s1 text by default
 */
const LoginFooter = ({footerText = null}) => {
  const { t } = useTranslation();
  const text = (footerText && typeof footerText === "string" && footerText !== "") ? footerText : (t('footer.copyright', {this_year:new Date().getFullYear()}));
  return (
    <div className="footer bg-primary" id="login_footer">
      <div className="row mt-1">
        <div className="col-sm footer-text" id="footer_text">{text}</div>
      </div>
    </div>
  );
};

export default LoginFooter;
